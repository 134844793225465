"use client"

import { useEffect } from "react"
import Bugsnag from "@bugsnag/js"

import { Button } from "@/components/ui/button"

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    Bugsnag.notify(error, (event) => {
      event.severity = "error"
      event.unhandled = true
    })
  }, [error])

  return (
    <html>
      <body>
        <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100">
          <h2 className="mb-4 text-2xl font-bold text-red-600">
            Something went wrong!
          </h2>
          <p className="mb-4 text-center text-gray-600">
            We apologize for the inconvenience. An unexpected error has
            occurred.
          </p>
          <Button onClick={() => reset()} variant="outline">
            Try again
          </Button>
        </div>
      </body>
    </html>
  )
}
